import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { StaticImage } from "gatsby-plugin-image"
import React, { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { FormProvider, useForm } from "react-hook-form"
import * as yup from "yup"

import { IUseContact } from "../../../dtos/IContact.dto"
import { useContact } from "../../../hooks/useContact"
import { States } from "../../../mocks/states"
import InputComponent from "../../forms/inputComponent"
import SelectComponent from "../../forms/select"
import TextAreaComponent from "../../forms/textArea"

interface DoubtModalProps {
  isOpen: boolean
  onClose: () => void
}

const schemaValidation = yup.object({
  name: yup.string().required("Nome é obrigatório"),
  email: yup.string().required("E-mail é obrigatório"),
  tel: yup.string().required("Telefone é obrigatório"),
  city: yup.string().required("Cidade é obrigatório"),
  state: yup.string().required("Estado é obrigatório"),
  comentario: yup.string().required("Comentário é obrigatório"),
})

export const DoubtModal = ({ isOpen, onClose }: DoubtModalProps) => {
  const methods = useForm<IUseContact>({
    resolver: yupResolver(schemaValidation),
  })
  const { mutateAsync: createContact, isLoading } = useContact()
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: "bottom",
  })

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*, video/*" as any,
    multiple: false,
    onDrop: useCallback(async (acceptedFiles: File[]) => {
      methods.setValue("file", acceptedFiles[0])
    }, []),
  })

  const handleSubmit = async (data: IUseContact) => {
    const response = await createContact(data)

    if (response?.data?.status === "mail_failed") {
      return toast({
        title: "Erro ao enviar e-mail",
        description:
          "Não foi possível enviar o e-mail, tente novamente mais tarde.",
        status: "error",
      })
    }

    toast({
      title: "E-mail enviado com sucesso",
      description: "Em breve entraremos em contato.",
      status: "success",
    })

    onClose()
    methods.reset()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{
        base: "full",
        md: "md",
      }}
    >
      <ModalOverlay />
      <ModalContent borderRadius="1.5rem">
        <ModalHeader>
          <Stack alignItems="center" justifyContent="center">
            <Box height="2rem">
              <StaticImage src="../../../images/logo.svg" alt="Logo" />
            </Box>
            <Text fontFamily="P22 Mackinac Pro" fontSize="2rem">
              Dúvida
            </Text>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...methods}>
            <Stack spacing={6}>
              <InputComponent
                name="name"
                label="Nome"
                placeholder="Ex.: João da Silva"
              />
              <InputComponent
                name="email"
                label="Seu melhor e-mail"
                placeholder="exemplo@email.com"
              />
              <InputComponent
                name="tel"
                label="Telefone"
                placeholder="Ex.: (51) 99999-9999"
                mask="(99) 99999-9999"
              />
              <SimpleGrid
                columns={{
                  base: 1,
                  md: 2,
                }}
                spacing={4}
              >
                <InputComponent
                  name="city"
                  label="Sua Cidade"
                  placeholder="Ex.: Porto Alegre"
                />
                <SelectComponent
                  name="state"
                  label="Seu estado"
                  placeholder="Ex.: RS"
                  options={States.map(state => ({
                    name: state.nome,
                    value: state.sigla,
                  }))}
                />
              </SimpleGrid>
              <TextAreaComponent
                name="comentario"
                label="Descreva sua dúvida"
                placeholder="Escreva sua mensagem!"
              />

              <div>
                <div className="py-2 my-0 chakra-form__label ">
                  Anexar fotos se desejar
                </div>
                <Stack
                  {...getRootProps({ className: "dropzone" })}
                  border="dashed 2px #025F1D"
                  h="200px"
                  align="center"
                  justify="center"
                  cursor="pointer"
                >
                  <Stack align="center">
                    <input {...getInputProps()} />
                    <StaticImage src="../../../images/file.svg" alt="Upload" />
                    <Button
                      variant="outline"
                      color="#025F1D"
                      borderRadius="12.5rem"
                    >
                      Procurar arquivos
                    </Button>
                  </Stack>

                  {methods.watch("file") && (
                    <Text
                      as="span"
                      fontSize="0.75rem"
                      fontWeight="500"
                      lineHeight="125%"
                      color="#495754"
                      textAlign="center"
                    >
                      {methods.watch("file")?.name}
                    </Text>
                  )}
                </Stack>
              </div>
              <Text
                as="span"
                fontSize="0.75rem"
                fontWeight="500"
                lineHeight="125%"
                color="#495754"
                textAlign="center"
              >
                *Sua privacidade é nossa prioridade. Garantimos que suas
                informações serão mantidas em sigilo e não serão compartilhadas
                sem o seu consentimento.
              </Text>
            </Stack>
          </FormProvider>
        </ModalBody>

        <ModalFooter>
          <Flex justifyContent="center" alignItems="center" width="100%">
            <Button
              background="#025F1D"
              mr={3}
              onClick={methods.handleSubmit(handleSubmit)}
              color="#fff"
              width="23.93rem"
              borderRadius="12.5rem"
              _hover={{ background: "#014414c5" }}
              isLoading={isLoading}
            >
              Enviar
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
