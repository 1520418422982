import {
  Box,
  Button,
  Flex,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import React, { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { FormProvider, useForm } from "react-hook-form"
import * as yup from "yup"

import { yupResolver } from "@hookform/resolvers/yup"
import { IUseComplain } from "../../../dtos/IContact.dto"
import { useComplain } from "../../../hooks/useContact"
import { Products } from "../../../mocks/products"
import { States } from "../../../mocks/states"
import InputComponent from "../../forms/inputComponent"
import SelectComponent from "../../forms/select"
import TextAreaComponent from "../../forms/textArea"

interface DoubtModalProps {
  isOpen: boolean
  onClose: () => void
}

const schemaValidation = yup.object({
  name: yup.string().required("Nome é obrigatório"),
  email: yup.string().required("E-mail é obrigatório"),
  telefone: yup.string().required("Telefone é obrigatório"),
  cidade: yup.string().required("Cidade é obrigatório"),
  estado: yup.string().required("Estado é obrigatório"),
  assunto: yup.string().required("Assunto é obrigatório"),
  produtocomprado: yup.string().required("Produto é obrigatório"),
  lote: yup.string().required("Lote é obrigatório"),
  datadevalidade: yup.string().required("Data de validade é obrigatório"),
  datadefabricao: yup.string().required("Data de fabricação é obrigatório"),
  localondefoirealizadaacompradoproduto: yup
    .string()
    .required("Local de compra é obrigatório"),
  retorno: yup.string().required("Retorno é obrigatório"),
  mensagem: yup.string().required("Mensagem é obrigatório"),
})

export const ComplainModal = ({ isOpen, onClose }: DoubtModalProps) => {
  const methods = useForm<IUseComplain>({
    resolver: yupResolver(schemaValidation),
  })
  const { mutateAsync: createComplain, isLoading } = useComplain()
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: "bottom",
  })

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*, video/*" as any,
    multiple: false,
    onDrop: useCallback(async (acceptedFiles: File[]) => {
      methods.setValue("fotodoproduto", acceptedFiles[0])
    }, []),
  })

  const handleSubmit = async (data: IUseComplain) => {
    const response = await createComplain(data)

    if (response?.data?.status === "mail_failed") {
      return toast({
        title: "Erro ao enviar e-mail",
        description:
          "Não foi possível enviar o e-mail, tente novamente mais tarde.",
        status: "error",
      })
    }

    toast({
      title: "E-mail enviado com sucesso",
      description: "Em breve entraremos em contato.",
      status: "success",
    })

    onClose()
    methods.reset()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{
        base: "full",
        md: "md",
      }}
    >
      <ModalOverlay />
      <ModalContent borderRadius="1.5rem">
        <ModalHeader>
          <Stack alignItems="center" justifyContent="center">
            <Box height="2rem">
              <StaticImage src="../../../images/logo.svg" alt="Logo" />
            </Box>
            <Text fontFamily="P22 Mackinac Pro" fontSize="2rem">
              Reclamação
            </Text>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...methods}>
            <Stack spacing={6}>
              <InputComponent
                name="name"
                label="Nome"
                placeholder="Ex.: João da Silva"
              />
              <InputComponent
                name="email"
                label="Seu melhor e-mail"
                placeholder="exemplo@email.com"
              />
              <InputComponent
                name="telefone"
                label="Telefone"
                placeholder="Ex.: (51) 99999-9999"
                mask="(99) 99999-9999"
              />
              <SimpleGrid
                columns={{
                  base: 1,
                  md: 2,
                }}
                spacing={4}
              >
                <InputComponent
                  name="cidade"
                  label="Sua Cidade"
                  placeholder="Ex.: Porto Alegre"
                />
                <SelectComponent
                  name="estado"
                  label="Seu estado"
                  placeholder="Ex.: RS"
                  options={States.map(state => ({
                    name: state.nome,
                    value: state.sigla,
                  }))}
                />
              </SimpleGrid>
              <InputComponent
                name="assunto"
                label="Assunto"
                placeholder="Ex.: Reclamação"
              />
              <SelectComponent
                name="produtocomprado"
                label="Escolha o Produto"
                options={Products.map(product => ({
                  name: product,
                  value: product,
                }))}
              />

              <SimpleGrid
                columns={{
                  base: 1,
                  md: 2,
                }}
                spacing={4}
              >
                <SelectComponent
                  name="retorno"
                  label="Horário preferencial para retorno"
                  options={["Manhã", "Tarde"].map(product => ({
                    name: product,
                    value: product,
                  }))}
                />
                <InputComponent
                  name="lote"
                  label="Lote"
                  placeholder="Ex.: 123456789"
                />
              </SimpleGrid>
              <SimpleGrid
                columns={{
                  base: 1,
                  md: 2,
                }}
                spacing={4}
              >
                <InputComponent
                  name="datadevalidade"
                  label="Data de validade"
                  placeholder="Ex.: 01/01/2021"
                  type="date"
                />
                <InputComponent
                  name="datadefabricao"
                  label="Data de fabricação"
                  placeholder="Ex.: 01/01/2021"
                  type="date"
                />
              </SimpleGrid>
              <InputComponent
                name="localondefoirealizadaacompradoproduto"
                label="Local de compra"
                placeholder="Ex.: Supermercado X"
              />

              <TextAreaComponent
                name="mensagem"
                label="Nos dê mais detalhes"
                placeholder="Escreva sua mensagem!"
              />
              <div>
                <div className="py-2 my-0 chakra-form__label ">
                  Envie fotos do produto relacionado à sua solicitação
                </div>
                <Stack
                  {...getRootProps({ className: "dropzone" })}
                  border="dashed 2px #025F1D"
                  h="200px"
                  align="center"
                  justify="center"
                  cursor="pointer"
                >
                  <Stack align="center">
                    <input {...getInputProps()} />
                    <StaticImage src="../../../images/file.svg" alt="Upload" />
                    <Button
                      variant="outline"
                      color="#025F1D"
                      borderRadius="12.5rem"
                    >
                      Procurar arquivos
                    </Button>
                  </Stack>

                  {methods.watch("fotodoproduto") && (
                    <Text
                      as="span"
                      fontSize="0.75rem"
                      fontWeight="500"
                      lineHeight="125%"
                      color="#495754"
                      textAlign="center"
                    >
                      {methods.watch("fotodoproduto")?.name}
                    </Text>
                  )}
                </Stack>
              </div>
              <Text
                as="span"
                fontSize="0.75rem"
                fontWeight="500"
                lineHeight="125%"
                color="#495754"
                textAlign="center"
              >
                *Sua privacidade é nossa prioridade. Garantimos que suas
                informações serão mantidas em sigilo e não serão compartilhadas
                sem o seu consentimento.
              </Text>
            </Stack>
          </FormProvider>
        </ModalBody>

        <ModalFooter>
          <Flex justifyContent="center" alignItems="center" width="100%">
            <Button
              background="#025F1D"
              mr={3}
              onClick={methods.handleSubmit(handleSubmit)}
              color="#fff"
              width="23.93rem"
              borderRadius="12.5rem"
              _hover={{ background: "#014414c5" }}
              isLoading={isLoading}
            >
              Enviar
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
