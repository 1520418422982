export const Products = [
  "Creme de leite lacfree 500G",
  "Creme de Leite Tradicional 500g",
  "Iogurte Kids Banana e Maçã Verde Campo 170g",
  "Iogurte Kids Morango Verde Campo 170g",
  "Iogurte lacfree 140g - cranberry e gojiberry",
  "Iogurte lacfree 140g - morango",
  "Iogurte lacfree 140g - tradicional",
  "Iogurte lacfree 140g -banana, maça e mamão",
  "Iogurte Lacfree Ameixa Verde Campo 500g",
  "Iogurte Lacfree Banana, Maçã e Mamão Verde Campo 170g",
  "Iogurte Lacfree Banana, Maçã e Mamão Verde Campo 500g",
  "Iogurte Lacfree Coco Verde Campo 500g",
  "Iogurte Lacfree Cranberry e Gojiberry Verde Campo 500g",
  "Iogurte Lacfree Morango Verde Campo 170g",
  "Iogurte Lacfree Morango Verde Campo 500g",
  "Iogurte Lacfree Natural Verde Campo 500g",
  "Iogurte Lacfree Tradicional Verde Campo 170g",
  "Iogurte Lacfree Tradicional Verde Campo 500g",
  "Iogurte Natural Desnatado 2 Ingredientes Lacfree 160g",
  "Iogurte Natural Desnatado Verde Campo 140g",
  "Iogurte Natural Integral 2 Ingredientes 160g",
  "Iogurte Natural Whey Banana 14g de Proteína",
  "Iogurte Natural Whey Baunilha 14g de Proteína Verde Campo 250g",
  "Iogurte Natural Whey Coco 21g de Proteína Verde Campo 250g",
  "Iogurte Natural Whey Coco 28g de Proteína Verde Campo 500g",
  "Iogurte Natural Whey Colherável Bicamada Abacaxi com Coco 11g de Proteína Verde Campo 140g",
  "Iogurte Natural Whey Colherável Bicamada Banana com Canela 11g de Proteína Verde Campo 140g",
  "Iogurte Natural Whey Colherável Bicamada Frutas Vermelhas 11g de Proteína Verde Campo 140g",
  "Iogurte Natural Whey Colherável Bicamada Maracujá 11g de Proteína Verde Campo 140g",
  "Iogurte Natural Whey Colherável Tradicional 14g de Proteína Verde Campo 140g",
  "Iogurte Natural Whey Cookies and Cream 14g de Proteína Verde Campo 250g",
  "Iogurte Natural Whey Cookies and Cream 28g de Proteína Verde Campo 500g",
  "Iogurte Natural Whey Cranberry e Goji berry Com Adição de Colágeno Verisol 10g de Proteína Verde Campo 170g",
  "Iogurte Natural Whey Doce de Leite 21g de Proteína Verde Campo 250g",
  "Iogurte Natural Whey Jabuticaba 14g de Proteína Verde Campo 250g",
  "Iogurte Natural Whey Morango 14g de Proteína Verde Campo 250g",
  "Iogurte Natural Whey Morango 21g de Proteína Verde Campo 250g",
  "Iogurte Natural Whey Morango 28g de Proteína Verde Campo 500g",
  "Iogurte Natural Whey Pasta de Amendoim 21g de Proteína Verde Campo 250g",
  "Iogurte Natural Whey Pêssego Com Adição de Colágeno Verisol 10g de Proteína Verde Campo 170g",
  "Iogurte Natural Whey Torta de Limão 21g de Proteína Verde Campo 250g",
  "Iogurte Natural Whey Tradicional 28g de Proteína Verde Campo 500g",
  "Iogurte Probótico Ameixa Triplo Zero Verde Campo 500g",
  "Iogurte Probótico Ameixa Verde Campo 500g",
  "Iogurte Probótico Banana, Maçã e Mamão Verde Campo 170g",
  "Iogurte Probótico Coco Verde Campo 500g",
  "Iogurte Probótico Maçã, Banana e Mamão Verde Campo 500g",
  "Iogurte Probótico Morango Triplo Zero Verde Campo 500g",
  "Iogurte Probótico Morango Verde Campo 170g",
  "Iogurte Probótico Morango Verde Campo 500g",
  "Iogurte Probótico Tradicional Verde Campo 500g",
  "Kefir Lacfree Morango Verde Campo 500g",
  "Kefir Lacfree Tradicional Verde Campo 500g",
  "Queijo Frescal Tradicional",
  "Queijo Minas Frescal 250g",
  "Queijo minas frescal 450g",
  "Queijo minas padrão 450g",
  "Queijo minas padrão lacfree 450g",
  "Queijo minas padrão tradicional 450g",
  "Queijo mussarela fatiada lacfree 150g",
  "Queijo mussarela lacfree 500G",
  "Queijo tipo cottage lacfree 200g",
  "Queijo tipo cottage lacfree 400g",
  "Queijo tipo cottage tradicional -200g",
  "Queijo tipo cottage tradicional -400g",
  "Requeijão cremoso lacfree 180g",
  "Requeijão Cremoso LACFREE Verde Campo 400g",
  "Shake Natural Whey Baunilha 14g de Proteína Verde Campo 250ml",
  "Shake Natural Whey Cappuccino 14g de Proteína Verde Campo 250ml",
  "Shake Natural Whey Caramelo 14g de Proteína Verde Campo 250ml",
  "Shake Natural Whey Chocolate 14g de Proteína Verde Campo 250ml",
  "Sobremesa Banana e Canela Verde Campo 100g",
  "Sobremesa Frutas Vermelhas Verde Campo 100g"
]