import {
  Box,
  FormLabel,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Text,
  Textarea,
  TextareaProps,
} from "@chakra-ui/react"
import React from "react"
import { useFormContext } from "react-hook-form"

interface Props extends TextareaProps {
  name: string
  leftIcon?: React.ReactNode
  isLoading?: boolean
  label?: string
}

export const TextAreaComponent: React.FC<Props> = ({
  name,
  leftIcon,
  isLoading,
  label,
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <Box flex={rest?.flex}>
      {label && (
        <FormLabel
          htmlFor={name}
          fontSize=".875rem"
          fontWeight={"400"}
          fontFamily="Plus Jakarta Sans"
        >
          {label}
        </FormLabel>
      )}
      <InputGroup>
        {leftIcon && (
          <InputLeftElement
            pointerEvents="none"
            color="gray.300"
            fontSize="1.2em"
            mt="-5px"
          >
            {leftIcon}
          </InputLeftElement>
        )}

        <Textarea
          id={name}
          w="full"
          isInvalid={!!errors[name]}
          errorBorderColor="red.300"
          _placeholder={{
            fontSize: "1rem",
          }}
          {...rest}
          {...register(name)}
        />

        {isLoading && (
          <InputRightElement width="4.5rem" height="100%">
            <Spinner color="primary.500" />
          </InputRightElement>
        )}
      </InputGroup>

      {errors?.[name] && (
        <Text
          pl="10px"
          textAlign="start"
          marginTop="4px !important"
          lineHeight="15px"
          fontSize="12px"
          color={errors?.[name] ? "red.300" : "text.label"}
          textStyle="body.small"
        >
          {errors?.[name]?.message as any}
        </Text>
      )}
    </Box>
  )
}

export default TextAreaComponent
